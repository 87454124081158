import React from "react";

/*
    Defining icons used in table display

    Current defined icons
    checkCircle - checkmark in a circle
    xSquareFill - X in a filled square
    seeMore - Right arrow in box
    edit - pen in square icon
    delete - filled trashcan icon
    copy - Clipboard with plus sign, used for copy to clipboard
    download_cloud - cloud download icon
    upload - Upload icon in half box - used for forcing POS update in update manager
    Add - Plus in square box
 */

export function importIcon(iconType, iconSize, additionalClassName = '') {
  switch (iconType) {
    case 'checkCircle':
      return (<svg className={"bi bi-check-circle " + additionalClassName}
                   width={iconSize} height={iconSize} viewBox="0 0 16 16"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path fillRule="evenodd"
              d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324
              8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
        />
      </svg>)
    case 'xCircle':
      return (
        <svg width={iconSize} height={iconSize} viewBox="0 0 16 16"
             className={"bi bi-x-circle " + additionalClassName}
             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
          />
          <path fillRule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707
                8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293
                8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      )
    case 'xSquareFill':
      return (
        <svg className={"bi bi-x-square-fill " + additionalClassName}
             width={iconSize} height={iconSize} viewBox="0 0 16 16"
             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0
            2-2V2a2 2 0 0 0-2-2H2zm9.854 4.854a.5.5 0 0 0-.708-.708L8 7.293 4.854
            4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8
            8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z"
          />
        </svg>
      )
    case 'seeMore':
      return (
        <svg className={"bi bi-caret-right-square" + additionalClassName}
             width={iconSize} height={iconSize}
             viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <title>See More</title>
          <path fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1
                1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0
                2-2V2a2 2 0 0 0-2-2H2z"
          />
          <path fillRule="evenodd"
                d="M5.795 12.456A.5.5 0 0 1 5.5 12V4a.5.5 0 0 1 .832-.374l4.5
                4a.5.5 0 0 1 0 .748l-4.5 4a.5.5 0 0 1-.537.082z"
          />
        </svg>
      );
    case 'edit':
      return (
        <svg className={"bi bi-pencil-square" + additionalClassName}
             width={iconSize} height={iconSize}
             viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <title>Edit</title>
          <path
            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707
            0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805
            2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
          />
          <path fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1
                0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5
                0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
          />
        </svg>
      );
    case 'delete':
      return (
        <svg className={"bi bi-trash-fill" + additionalClassName}
             width={iconSize} height={iconSize}
             viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <title>Delete</title>
          <path fillRule="evenodd"
                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0
                2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1
                1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8
                5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1
                0v7a.5.5 0 0 0 1 0v-7z"
          />
        </svg>
      );
    case 'copy':
      return (
        <svg width={iconSize} height={iconSize} viewBox="0 0 16 16"
             className="bi bi-clipboard-plus"
             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2
                        2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0
                        1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
          <path fillRule="evenodd"
                d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0
                        .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5
                        0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zM8
                        7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0
                        1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
        </svg>
      );
    case 'download_cloud':
      return (
        <svg width={iconSize} height={iconSize} viewBox="0 0 16 16"
             className="bi bi-cloud-arrow-down"
             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166
                      4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13
                      12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223
                      2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153
                      1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785
                      2.23 12 3.781 12h8.906C13.98 12 15 10.988 15
                      9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328
                      3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
          />
          <path fillRule="evenodd"
                d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5
                      9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
          />
        </svg>
      );
    case 'upload':
      return (
        <svg width={iconSize} height={iconSize} viewBox="0 0 16 16" className="bi bi-upload"
             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5
                      0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8zM5
                      4.854a.5.5 0 0 0 .707 0L8 2.56l2.293 2.293A.5.5 0 1 0 11 4.146L8.354
                      1.5a.5.5 0 0 0-.708 0L5 4.146a.5.5 0 0 0 0 .708z"
          />
          <path fillRule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 2z"/>
        </svg>
      );
    case 'add':
      return (
        <svg className={"bi bi-plus-square" + additionalClassName}
             width={iconSize} height={iconSize}
             viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1
              0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
          <path fillRule="evenodd"
                d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
          <path fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1
              1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0
              2-2V2a2 2 0 0 0-2-2H2z"/>
        </svg>
      );
    default:
      return ('');
  }
}
