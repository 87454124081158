import React from 'react';
import Settings from './settings';

/*
    Set the common footer JSX
 */

// set copyright date string
let copyDates = Settings.StartCopyrightDate;
const year = new Date().getFullYear().toString();
if (year !== copyDates) {
  copyDates += "-" + year;
}

function Footer() {
  return (
    <footer className="App-footer">
      <br/>
      <hr/>
      <div className="small">
        &copy; {copyDates} - <a href={Settings.CMI_URL}>CMI Solutions</a>
        <div className="small float-right">Version {Settings.Version}</div>
      </div>
    </footer>
  );
}

export default Footer;

