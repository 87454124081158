import React, {Component} from "react";
import axiosInstance from "../common/axiosApi";
import {Redirect} from "react-router-dom";

/*
  Logout component

  Removes token information from browser
  then redirects to login page

 */

class Logout extends Component {

  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    try {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      axiosInstance.defaults.headers['Authorization'] = null;
    } catch (e) {
      console.log("Logout Error: ", e);
    }
  };

  componentDidMount() {
    this.handleLogout();
  }

  render() {
    return <Redirect to={'/login'}/>;
  }

}

export default Logout;
