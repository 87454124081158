import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/Button";
import axiosInstance from "../common/axiosApi";

/*
    Global delete Modal
 */

class Delete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: this.props.modal.title || "Delete",
      modalBody: this.props.modal.body || "Are you sure you want to delete this item?",
    }
  };

  returnStatus(value) {
    if (value !== 'Delete') {
      this.props.callbackFromParent(false);
      return false;
    }
    axiosInstance.delete(this.props.modal.deleteUrl)
      .then(result => {
        this.props.callbackFromParent(result);
      })
      .catch(error => {
        alert('There was an error:' + error);
      })
  }

  render() {
    return (
      <Modal show={true} onHide={this.returnStatus.bind(this, 'Cancel')}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{this.state.modalBody}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="info"
                  onClick={this.returnStatus.bind(this, 'Cancel')}>Cancel</Button>
          <Button variant="danger"
                  onClick={this.returnStatus.bind(this, 'Delete')}>Delete</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Delete;
