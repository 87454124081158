import React from 'react';
import Settings from "./settings";
import {Button, Dropdown, DropdownButton, ButtonGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";

/*
    Set the common header JSX
 */

function Header() {
  return (
    <header className="App-header header">
      <div className={'float-left header-text'}>{Settings.AppName}</div>
      <div className="float-right">
        <nav>
          <ButtonGroup>
            <Link to={"/home"}><Button variant="dark">Home</Button></Link>
            <DropdownButton variant="dark" id="dropdown-button" title="Admin">
              <Dropdown.Item href={"user-security"}>
                User Security
              </Dropdown.Item>
              <Dropdown.Item href={"logout"}>
                Log Out
              </Dropdown.Item>
            </DropdownButton>
            <Link to={"/about"}><Button variant="dark">About</Button></Link>
          </ButtonGroup>
        </nav>
      </div>
    </header>
  );
}

export default Header;
