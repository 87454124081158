import React from "react";
import axiosInstance from "../common/axiosApi";
import BootstrapTable from 'react-bootstrap-table-next';
import {importIcon} from "../common/tables";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {LoadingIndicator} from "../common/loadingIndicator";
import {trackPromise} from 'react-promise-tracker';

class Stores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {showDelete: false, showAddEdit: false},
      newStore: {},
      data: [],
      table: '',
      loaded: false,
      breadcrumb: 'POS Devices for Install ID: ' + this.props.match.params.installID
    };
  }

  componentDidMount() {
     this.updateTable();
  }

  updateTable() {
    const urlStore = '/gui/stores/' + this.props.match.params.installID;
    trackPromise(
      axiosInstance.get(urlStore)
        .then(result => {
          let table = this.getStoresTable(result.data);
          this.setState({table: table});
        })
        .catch(error => console.log('There was an error:' + error))
    )
  }

  getStoresTable(data) {
    const {SearchBar} = Search;

    const columns = [
      {dataField: 'Code', text: 'Store ID', sort: true},
      {dataField: 'Name', text: 'Store Name', sort: true},
      {dataField: 'InactiveFlag', text: 'Active', sort: false,
        headerStyle: () => {
          return {width: "100px"}
        },
        style: {textAlign: 'center'}
      },
      {dataField: 'last_active', text: 'Last Active', sort: true},
      {dataField: 'created_at', text: 'Created', sort: true},
      {dataField: 'updated_at', text: 'Last Report', sort: true},
    ]

    data.forEach(row => {
      if (row['InactiveFlag']) {
        row['InactiveFlag'] = importIcon('xCircle', '1.5em', 'text-danger');
      } else {
        row['InactiveFlag'] = importIcon('checkCircle', '1.5em', 'text-success');
      }
      row['last_active'] = row['last_active'].substr(0, 10)
      row['created_at'] = row['created_at'].substr(0, 10)
      row['updated_at'] = row['updated_at'].substr(0, 10)

    })

    return (
      <div>
        <ToolkitProvider
          keyField='Code' bootstrap4 data={data} columns={columns} search
        >
          {
            props => (
              <div>
                  <div className={'float-right'}>
                    <SearchBar {...props.searchProps} className={'float-right'}/>
                  </div>
                <BootstrapTable {...props.baseProps}
                                defaultSorted={[{dataField: 'Code', order: 'asc'}]}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    )
  }

  modalReturn = (dataFromModal) => {
    // Clear all modals
    this.setState({modal: {}});
    this.updateTable();
  }

  render() {
    return (
      <div>
        <div className={'pageTitle'}>{this.state.breadcrumb}</div>
        <br/><br/>
        <LoadingIndicator/>
        {this.state.table}
      </div>
    );
  }
}

export default Stores;
