import React from "react";
import axiosInstance from "../common/axiosApi";
import BootstrapTable from 'react-bootstrap-table-next';
import {importIcon} from "../common/tables";
import Delete from "../modals/Delete";
import AddEdit from "../modals/AddEdit";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {LoadingIndicator} from "../common/loadingIndicator";
import {trackPromise} from 'react-promise-tracker';
import moment from "moment";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {showDelete: false, showAddEdit: false},
      data: [],
      table: '',
      loggedInUser: {},
      loaded: false,
    };
  }

  componentDidMount() {
    this.updateTable();
  }

  updateTable() {
    trackPromise(
      axiosInstance.get('users')
        .then(result => {
          let table = this.getUserTable(result.data);
          this.setState({table: table, loggedInUser: result.data.logged_in_user});
        })
        .catch(error => console.log('There was an error:' + error))
    )
  }

  getUserTable(resultData) {
    let data = resultData.users
    let user = resultData.logged_in_user
    const {SearchBar} = Search;
    let add_link = (column, colIndex) => {
      return (
        <a href="#no_move" onClick={this.addEditUserCall.bind(
          this, null
        )}>
          {importIcon('add', '2em')}
        </a>
      )
    }

    const columns = [
      {dataField: 'username', text: 'User Name', sort: true},
      {dataField: 'staffIndicator', text: 'Is Admin', sort: true},
      {dataField: 'activeIndicator', text: 'Inactive', sort: true},
      {dataField: 'last_login', text: 'Last Login', sort: true},
      {dataField: 'date_joined', text: 'Date Joined', sort: true},
      {
        dataField: 'edit_del', text: '',
        headerFormatter: add_link,
        headerStyle: () => {
          return {width: "100px"}
        }
      }
    ]
    // let staff = 0;
    // data.forEach(row => {
    //   if (row['is_staff']) {
    //     staff += 1;
    //   }
    // })
    data.forEach(row => {
      if (row['is_staff']) {
        row["staffIndicator"] = importIcon('checkCircle', '1.5em', 'text-success');
      } else {
        // row["staffIndicator"] = importIcon('xSquareFill', '1.5em', 'text-danger');
      }

      // go returns is_inactive, django returns is_active, deal with each separately
      if ('is_inactive' in row) {
        if (row['is_inactive']) {
          row["activeIndicator"] = importIcon('checkCircle', '1.5em', 'text-danger');
        }

      } else {
        if (!row['is_active']) {
          row["activeIndicator"] = importIcon('checkCircle', '1.5em', 'text-danger');
        }
      }
      // if ((! row['is_staff'] || staff > 1) && row['id'] !== user.id) {
      // can't delete yourself

      if (row['id'] !== user.id) {
        row['edit_del'] = (
          <div>
            <a href="#no_move" onClick={this.addEditUserCall.bind(
              this, row
            )}>
              {importIcon('edit', '2em')}
            </a>
            {' '}
            <a href="#no_move" onClick={this.deleteUserCall.bind(
              this, row
            )}>
              {importIcon('delete', '2em')}
            </a>
          </div>
        )
      } else {
        row['edit_del'] = (
          <div>
            <a href="#no_move" onClick={this.addEditUserCall.bind(
              this, row
            )}>
              {importIcon('edit', '2em')}
            </a>
          </div>
        )
      }
      // Change display of date/time for last_login and date_joined
      row['last_login'] = moment(row['last_login']).format("YYYY-MM-DD h:mm a");
      row['date_joined'] = moment(row['date_joined']).format("YYYY-MM-DD h:mm a");
    })

    return (
      <div>
        <ToolkitProvider
          keyField='id' bootstrap4 data={data} columns={columns} search
        >
          {
            props => (
              <div>
                <div className={'float-right'}>
                  <SearchBar {...props.searchProps} className={'float-right'}/>
                </div>
                <BootstrapTable {...props.baseProps}
                                defaultSorted={[{dataField: 'username', order: 'asc'}]}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    )
  }

  deleteUserCall(row) {
    const modalBody = 'Are you sure you want to delete user: ' + row['username'] + "?";
    this.setState({
      modal: {
        showDelete: true,
        title: "Delete " + row['username'],
        body: modalBody,
        deleteUrl: 'user/' + row['id']
      }
    });
  }

  addEditUserCall(row) {
    let title = "Add New User";
    let saveInfo = {
      url: 'users',
      method: 'post',
      data: {},
      id: 0,
    };

    let fieldsObject = [
      {type: 'text', field: 'username', value: '', description: 'User Name'},
      {
        type: 'password', field: 'new_password', value: '',
        description: 'New Password', placeholder: 'leave blank to keep current'
      },
      {type: 'bool', field: 'is_staff', value: false, description: 'Is Admin'},
      {type: 'bool', field: 'is_active', value: true, description: 'Inactive'},
    ]
    if (row) {
      title = "Edit User: " + row['username'];
      fieldsObject[0]['value'] = row['username'];
      fieldsObject[2]['value'] = row['is_staff'];
      fieldsObject[3]['value'] = row['is_active'];
      saveInfo.url = 'user/' + row['id'];
      saveInfo.id = row['id'];
      saveInfo.method = 'patch';
    }
    // Only edit password on the users own account
    if (row && row['id'] === this.state.loggedInUser.id) {
      fieldsObject = [fieldsObject[1]]
    }
    this.setState({
      modal: {
        showAddEdit: true, title: title, fieldsObject: fieldsObject,
        saveInfo: saveInfo, blockAutocomplete: true
      }
    });
  }

  modalReturn = (dataFromModal) => {
    // if changes/delete was to current user, force logout
    if (dataFromModal && dataFromModal.logged_in_user &&
      dataFromModal.logged_in_user.id === this.state.modal.saveInfo.id
    ) {
      alert('Changes made to logged in user requires user to log in again');
      this.props.history.push('/logout');
    }

    // Clear all modals
    this.setState({modal: {}});
    this.updateTable();
  }

  render() {
    return (
      <div>
        <div className={'pageTitle'}>User Maintenance</div>
        <br/><br/>
        {this.state.modal.showDelete && <Delete callbackFromParent={this.modalReturn}
                                                modal={this.state.modal}/>}
        {this.state.modal.showAddEdit && <AddEdit callbackFromParent={this.modalReturn}
                                                  modal={this.state.modal}/>}
        <LoadingIndicator/>
        {this.state.table}
      </div>
    );
  }
}

export default UserList;
