import React from "react";
import Settings from '../common/settings';
import axiosInstance from "../common/axiosApi";

/*
    Create and display the about page
 */
class About extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      api_version: 'loading ...',
      api_codebase: 'Loading ...'
    };
  }

  componentDidMount() {
    axiosInstance.post('about')
      .then(result => {
        let state = {
          api_version: result.data.version,
        }
        if (result.data.api_codebase) {
          state['api_codebase'] = result.data.api_codebase;
        }
        this.setState(state);
      })
      .catch(error => {
        console.log('There was an error:' + error);
        this.setState({
          api_version: "Unavailable",
          api_codebase: "Unavailable"
        });
      })
  }

  render() {
    return (
      <div>
        <a href={Settings.CMI_URL}>
          <img src="./images/CMI-LOGO-BANNER-237x70.png" alt="CMI Solutions Logo"/>
        </a>
        <br/><br/>
        <a href={Settings.CMI_URL}>
          www.cmisolutions.com
        </a>
        <br/><br/>
        {Settings.AppName}<br/>
        <div className={'small'}>
          UI Version {Settings.Version}<br/>
          API Version {this.state.api_version}<br/>
          API Codebase {this.state.api_codebase}
        </div>
      </div>
    );
  }
}

export default About;
