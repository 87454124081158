import axios from 'axios'
import Settings from "./settings";

/*
    Code for processing the ajax calls

    Gives us the ability to automatically refresh tokens
    Also gives us hooks for logging all requests/responses if in development
    Also gives us hooks if we want to do a full site clean on requests/responses

 */

const axiosInstance = axios.create({
  /* Base call for ajax */
  baseURL: Settings.API_URL,
  timeout: 5000,
  headers: {
    'Authorization': "Bearer " + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    'accept': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  request => clean_request(request)
)

axiosInstance.interceptors.response.use(
  response => clean_response(response),
  error => {
    /*
      On error, check for token issues and request a refresh
      If refresh fails, return to login page
     */
    const originalRequest = error.config;

    if (typeof error.response == 'undefined') {
      return Promise.reject(error);
    }
    if (error.response.status && error.response.status === 401) {
      if (originalRequest.url === '/token') {
        // '/token' is only called by login, no need to check further
        // if unauthorized, simply return rejected
        return Promise.reject(error);
      }
      if (!originalRequest.url.includes('token/refresh')) {
        // otherwise, lets check if we can refresh token
        const refresh_token = localStorage.getItem('refresh_token');
        return axiosInstance
          .post('/token/refresh', {refresh: refresh_token})
          .then((response) => {
            localStorage.setItem('access_token', response.data.access);
            if (response.data.refresh) {
              localStorage.setItem('refresh_token', response.data.refresh);
            }
            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
            originalRequest.headers['Authorization'] = "Bearer " + response.data.access;
            return axiosInstance(originalRequest);
          })
          .catch(error => {
            // failed to refresh token, redirect to login
            return window.location.href = './login';
          });
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance


// dealing with necessary transformations & logging

function clean_request(request) {
  // add any clean all code here
  if (Settings.Debug) {
    console.log("Axios Request to " + request.url + ": ", request);
  }
  return request;
}

function clean_response(response) {
  // add any clean all code here
  if (Settings.Debug) {
    console.log("Axios Response from " + response.config.url + ": ", response.data);
  }
  return response;
}
