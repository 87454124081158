import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/Button";
import axiosInstance from "../common/axiosApi";
import moment from "moment";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Settings from "../common/settings";

/*
    Global add/edit modal box

    This one is fairly complex to cover all uses
 */

class AddEdit extends React.Component {
  createTable() {
    /*
        Creates the table used as a form for capturing the add/edit data
     */
    return (
      this.state.modalFields.map((row, i) => {
        let valueHtml = "";
        let readOnly = (row.readOnly ? row.readOnly : false);
        // Switch based on type of information to be captured
        switch (row.type) {
          case'bool':
            valueHtml = (
              <input key={i} type={'checkbox'} checked={row.value}
                     onChange={val => this.changeState(i, val.target.checked)}
                     readOnly={readOnly}
              />
            )
            break;
          case 'numeric':
          case 'integer':
          case 'number':
          case 'text':
          case 'string':
            if (row.value == null) {
              row.value = '';
            }
            row.value = row.value.toString();
            if (row.readOnly) {
              readOnly = true;
            }
            valueHtml = (
              <input key={i} type='text' id={row.field} name={row.field}
                     value={row.value}
                     placeholder={row.placeholder}
                     style={{width: '100%'}}
                     onChange={val => this.changeState(i, val.target.value)}
                     readOnly={readOnly}
              />
            )
            break;
          case 'timePicker':
            const format = 'h:mm a';
            const initValue = moment(row.value, format);

            valueHtml = (
              <TimePicker
                showSecond={false}
                defaultValue={initValue}
                format={format}
                onChange={val => {
                  this.changeState(i, val);
                }}
                use12Hours
              />
            )
            break;
          case 'password':
            valueHtml = (
              <input key={i} type='password' id={row.field} name={row.field}
                     value={row.value}
                     placeholder={row.placeholder}
                     style={{width: '100%'}}
                     onChange={val => this.changeState(i, val.target.value)}
                     readOnly={readOnly}
              />
            )
            break;
          case 'select':
            valueHtml = (
              <select
                key={i} value={row.value}
                style={{width: '80%'}}
                onChange={val => this.changeState(i, val.target.value)}
                disabled={readOnly}
              >
                {row.choices.map((option) => {
                  if (typeof option === 'string') {
                    return (
                      <option key={i + "_" + option} value={option}>
                        {option}
                      </option>
                    );
                  } else {
                    return (
                      <option key={i + "_" + option.value}
                              value={option.value}>
                        {option.label}
                      </option>
                    );
                  }
                })}
              </select>
            )
            break;
          default:
        }
        return (
          <tr key={i + "_tr"}>
            <td>{row.description}</td>
            <td>
              {valueHtml}
            </td>
          </tr>
        )
      })
    )
  }

  constructor(props) {
    // Store props data provided for access in component
    super(props);
    let fieldsObject = this.props.modal.fieldsObject || {};
    // is_active should display as inactive, but then save as is_active
    // save is taken care of in
    fieldsObject.map((row, i) => {
      if (row.field === 'is_active' && row.description === 'Inactive') {
        row.value = !row.value;
      }
      return row;
    });
    this.state = {
      modalTitle: this.props.modal.title || "Add / Edit",
      modalMessage: this.props.modal.message,
      modalFields: fieldsObject,
      modalSaveInfo: this.props.modal.saveInfo,
      modal: {showNotice: false},
      blockAutocomplete: this.props.modal.blockAutocomplete || false,
    }
  };

  decodeErrorObject(errorObj) {
    // function to decode the error objects in order to display the errors received
    function decodeRecursive(decodeObj) {
      let sub_errors = [];
      Object.entries(decodeObj).map(([key, value], index) => {
        if (Array.isArray(value)) {
          let field = fields.filter(obj => {
            return obj.field === key
          });
          let field_key = key;
          if (field && field[0] && field[0].description.length) {
            field_key = field[0].description;
          }
          value.forEach((errStr, i) => {
            sub_errors.push(field_key + ": " + errStr);
          })
        } else if (typeof value === 'object' && value !== null) {
          let res = decodeRecursive(value);
          res.forEach(element => {
            sub_errors.push(element)
          })
        }
        return true;
      })
      return sub_errors;
    }

    if (Settings.Debug) {
      console.log("Error Api Object: ", errorObj);
    }
    let fields = this.state.modalFields;
    let errors = decodeRecursive(errorObj);
    return (
      <div className={'smallError alert-danger'}>
        {errors.map((error) => (<div key={error}>{error} <br/></div>))}
      </div>
    )
  }

  returnStatus(buttonValue) {
    // If not Save, simply return
    if (buttonValue !== 'Save') {
      this.props.callbackFromParent(false);
      return false;
    }
    // If Save, do local validation on the information prior to sending
    const saveInfo = this.state.modalSaveInfo;
    let data = saveInfo.data;
    this.state.modalFields.forEach(row => {
      // have to force a bool value if empty
      if (row.type === 'bool' && !row.value) {
        row.value = false;
      }
      data[row.field] = row.value;
    });
    let rawData = data;
    let validation = this.validateSendData(data)
    if (Object.keys(validation.errors).length) {
      if (Settings.Debug) {
        console.log("Validation Errors: ", validation.errors, rawData);
      }
      this.setState({modalMessage: this.decodeErrorObject(validation.errors)});
      return;
    }
    data = validation.data;


    // reverse is_active, as was displayed invalid
    if ('is_active' in data) {
      data.is_active = !data.is_active;
    }

    // data is valid (well as far as we can tell locally)
    // send data to API
    axiosInstance[saveInfo.method](saveInfo.url, data)
      .then(result => {
        this.props.callbackFromParent(result.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          this.setState({
            modalMessage: this.decodeErrorObject(
              error.response.data
            )
          });
        } else {
          console.log("Api Returned Error: ", error);
        }
      })
  }

  changeState(i, value) {
    // Process changes to data on modal so that we send most current change
    // when save is clicked
    let modalFields = this.state.modalFields;
    modalFields[i].value = value;
    if (modalFields[i].onChange) {
      modalFields = modalFields[i].onChange(i, value, modalFields)
    }
    this.setState({modalFields: modalFields});
  }

  render() {
    const blockAutocomplete = this.state.blockAutocomplete;
    return (
      <Modal show={true} onHide={this.returnStatus.bind(this, 'Cancel')}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3>{this.state.modalMessage}</h3>
          <form autoComplete={blockAutocomplete ? 'off' : 'on'}>
            <table style={{width: '100%'}}>
              <thead>
              <tr>
                <th>Setting</th>
                <th>Value</th>
              </tr>
              </thead>
              <tbody>
              {this.createTable()}
              </tbody>
            </table>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger"
                  onClick={this.returnStatus.bind(this, 'Cancel')}>Cancel</Button>
          <Button variant="primary"
                  onClick={this.returnStatus.bind(this, 'Save')}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  validateSendData(data) {
    // validate based on field definitions received from calling component
    let error_obj = {}
    this.state.modalFields.forEach(row => {
      let row_errors = [];
      // validations based on API information
      let setError = (errorString) => {
        if (row.validation_error_text && row.validation_error_text.length) {
          row_errors.push(row.validation_error_text);
        } else {
          row_errors.push(errorString);
        }
      }
      let hasValue = (fieldKey, onlyTrueBool = false) => {
        // hasValue reports true for not null boolean values and anything else with
        // where value has length. Sometimes we only want booleans that have a value
        // of true, of so set onlyTrueBool to true

        let fieldData = null;
        let fieldDef = this.state.modalFields.filter(
          obj => {
            return obj.field === fieldKey
          }
        );
        if (fieldDef && fieldDef[0]) {
          fieldData = fieldDef[0];
        } else {
          return false;
        }
        if (fieldData.value == null) {
          return false;
        }
        if (typeof fieldData.value !== 'boolean' && !fieldData.value.toString().length) {
          return false;
        }
        if (onlyTrueBool && typeof fieldData.value === 'boolean' && fieldData.value !== true) {
          return false;
        }
        return true;
      }
      // set tempRequired if this field is required BECAUSE another filed is set
      let tempRequired = false;
      if (row.validation_required_if) {
        if (hasValue(row.validation_required_if, true)) {
          tempRequired = true;
        }
      }
      // check for required, or tempRequired
      if (row.required || tempRequired) {
        if (!hasValue(row.field)) {
          setError("is required");
          // No point in further validating field if it has no data
          // Need to load error_obj before we can exit forEach loop
          error_obj[row.field] = row_errors;
          return;
        }
      } else {
        // if not required and blank, no need to do further validation
        if (!hasValue(row.field)) {
          // force blank to null if not required
          data[row.field] = null;
          return;
        }
      }

      // validation_length
      if (row.validation_length) {
        if (!(
          row.validation_length[0] <= row.value.length
          && row.value.length <= row.validation_length[1]
        )) {
          setError(
            "Must be between " + row.validation_length[0]
            + " and " + row.validation_length[1] + " characters long"
          );
        }
      }
      // validation_value
      if (row.validation_value) {
        if (!(
          row.validation_value[0] <= Number(row.value)
          && Number(row.value) <= row.validation_value[1]
        )) {
          setError(
            "Must be in the range of " + row.validation_value[0]
            + " to " + row.validation_value[1]
          );
        }
      }
      // Type checks
      // bool, select, string, integer, number
      if (row.type && row.value.length) {
        switch (row.type) {
          case 'bool':
            if (row.value !== true && row.value !== false) {
              setError('Must be set to true or false')
            }
            break;
          case 'select':
            let values = [];
            let labels = [];
            row.choices.forEach(sub_row => {
              values.push(sub_row.value.toString());
              labels.push(sub_row.label);
            });
            if (!values.includes(row.value.toString())) {
              setError('Must be in [' + labels.join(", ") + "]")
            }
            break;
          case 'string':
          case 'text':
            // no real check needed here
            break;
          case 'integer':
            if ( ! /^-?\d+$/.test(row.value)) {
              setError('Must be an integer')
            }
            break;
          case 'number':
            if (!isNaN(parseFloat(row.value)) && !isNaN(row.value - 0)) {
              setError('Must be numeric')
            }
            break;
          default:
            if (Settings.Debug) {
              console.log("Add/Edit validateSendData No type check for type " + row.type)
            }
        }

      }
      if (row_errors.length) {
        error_obj[row.field] = row_errors;
      }
    })

    if (Settings.Debug && error_obj && error_obj.length) {
      console.log('Internal Errors: ', error_obj);
    }
    return {data: data, errors: error_obj};
  }

}

export default AddEdit;
