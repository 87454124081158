/*
    Site wide settings
 */

const Settings = {
  AppName: 'POS Maintenance Hub',
  Version: '0.2103.001',
  StartCopyrightDate: '2020',
  API_URL: '/api',
  Debug: false,
  // Set homepage here and in package.json
  // Basepath: "posmaintenance",
};

if (process.env.NODE_ENV === 'development') {
  Settings.API_URL = 'http://localhost:8070/';
  Settings.Debug = true;
}

export default Settings;
