import React from "react";
import {Link} from "react-router-dom";
import axiosInstance from "../common/axiosApi";
import BootstrapTable from 'react-bootstrap-table-next';
import {importIcon} from "../common/tables";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {LoadingIndicator} from "../common/loadingIndicator";
import {trackPromise} from 'react-promise-tracker';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {showDelete: false, showAddEdit: false},
      newStore: {},
      data: [],
      table: '',
      loaded: false,
    };
  }

  componentDidMount() {
     this.updateTable();
  }

  updateTable() {
    trackPromise(
      axiosInstance.get('gui/licensing')
        .then(result => {
          let table = this.getLicenseTable(result.data);
          this.setState({table: table});
        })
        .catch(error => console.log('There was an error:' + error))
    )
  }

  getLicenseTable(data) {
    const {SearchBar} = Search;
    const columns = [
      {
        dataField: 'see_more', text: 'See More', headerStyle: () => {return {width: "75px"}}},
      {dataField: 'install_id', text: 'Install ID', sort: true},
      {dataField: 'active_stores', text: 'Active Stores', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'active_devices', text: 'Active Devices', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'active_instances', text: 'Active Instances', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'api_version', text: 'Installed API Version', sort: true, headerStyle: () => {return {width: "125px"}}},
      {dataField: 'max_stores', text: 'Max Stores', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'max_stores_at', text: 'Max Seen', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'max_devices', text: 'Max Devices', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'max_devices_at', text: 'Max Seen', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'max_instances', text: 'Max Instances', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'max_instances_at', text: 'Max Seen', sort: true, headerStyle: () => {return {width: "100px"}}},
      {dataField: 'created_at', text: 'Last Check In', sort: true},
    ]

    data.forEach(row => {
      row['see_more'] =
        <Link to={'/stores/' + row['install_id']}>
          {importIcon('seeMore', '2em')}
        </Link>;
      row['max_instances_at'] = row['max_instances_at'].substr(0, 10)
      row['max_stores_at'] = row['max_stores_at'].substr(0, 10)

    })

    return (
      <div>
        <ToolkitProvider
          keyField='install_id' bootstrap4 data={data} columns={columns} search
        >
          {
            props => (
              <div>
                <h3>Max Store/Instance counts during period
                  <div className={'float-right'}>
                    <SearchBar {...props.searchProps} className={'float-right'}/>
                  </div>
                </h3>
                <BootstrapTable {...props.baseProps}
                                defaultSorted={[{dataField: 'install_id', order: 'asc'}]}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    )
  }

  modalReturn = (dataFromModal) => {
    // Clear all modals
    this.setState({modal: {}});
    this.updateTable();
  }

  render() {
    return (
      <div>
        <div className={'pageTitle'}>POS Maintenance Installations Listing</div>
        <br/><br/>
        {/*{this.state.modal.showDelete && <Delete callbackFromParent={this.modalReturn}*/}
        {/*                                        modal={this.state.modal}/>}*/}
        {/*{this.state.modal.showAddEdit && <AddEdit callbackFromParent={this.modalReturn}*/}
        {/*                                          modal={this.state.modal}/>}*/}
        <LoadingIndicator/>
        {this.state.table}
      </div>
    );
  }
}

export default Home;
