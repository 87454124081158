import React, {Component} from "react";
import './App.css';
import {Route, Switch} from 'react-router-dom';

import About from './pages/About';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Stores from './pages/Stores';
import UserList from "./pages/UserList";
import {Redirect} from "react-router-dom";
import Settings from "./common/settings";

class App extends Component {

  componentDidMount() {
    document.title = Settings.AppName;
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path={'/home'} component={Home}/>
          <Route path={'/stores/:installID'} component={Stores}/>
          <Route path={'/user-security'} component={UserList}/>
          <Route path={'/logout'} component={Logout}/>
          <Route path={'/about'} component={About}/>
          <Route path={'/login'} component={Login}/>
          <Route><Redirect to={'/home'}/></Route>
        </Switch>
      </div>
    );
  }
}

export default App;
