import React, {Component} from "react";
import axiosInstance from "../common/axiosApi";
import {Redirect} from "react-router-dom";

/*
    Simple login page using JWT token check using the API
 */

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      Nickname: "",
      Password: "",
      redirect: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async handleSubmit(event) {
    event.preventDefault();
    axiosInstance.post('/token', {
      username: this.state.username,
      password: this.state.password,
      Nickname: this.state.username,
      Password: this.state.password,
    })
      .then(result => {
        const data = result.data;
        axiosInstance.defaults.headers['Authorization'] = "JWT " + data.access;
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        this.setState({redirect: true});
      })
      .catch(error => {
        this.setState({
          message:
            <div className={'alert alert-warning'}>
              Bad Username Password combination
            </div>
        });
        return true;
      })
  }

  render() {
    const {redirect} = this.state;

    if (redirect) {
      return <Redirect to={'/'}/>;
    }

    return (
      <div>
        <div className={'pageTitle'}>Login</div>
        <br/>
        <form onSubmit={this.handleSubmit} className={'tab'}>
          {this.state.message}
          <label>
            Username<br/>
            <input name="username" type="text" value={this.state.username}
                   onChange={this.handleChange}/>
          </label>
          <br/><br/>
          <label>
            Password<br/>
            <input name="password" type="password" value={this.state.password}
                   onChange={this.handleChange}/>
          </label>
          <br/><br/>
          <input type="submit" value="Submit"/>
        </form>
      </div>
    )
  }
}

export default Login;
