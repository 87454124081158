import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from "./app/common/header";
import Footer from "./app/common/footer";

import App from './app/App';
import {BrowserRouter} from "react-router-dom";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import Settings from "./app/common/settings";

// picking up a few things from the browser and using that information to
// to adjust some internals
if (window.location.hostname === 'localhost') {
  Settings.AppName = "*DEV* " + Settings.AppName
  Settings.Debug = true
} else if (window.location.hostname.toLowerCase().includes("scandata.cmisolutions")) {
  Settings.AppName = "*Beta* " + Settings.AppName
  Settings.Debug = true
}
  // lets figure out basepath since default seems incorrect
if (! window.location.pathname.includes(Settings.Basepath) ) {
  let path = window.location.pathname.split("/")
  if (path.length > 2) {
    Settings.Basepath = path[1]
  } else {
    Settings.Basepath = ''
  }
}

ReactDOM.render(
  <div>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
      crossOrigin="anonymous"
    />
    <BrowserRouter basename={Settings.Basepath}>
      <Header/>
      <App/>
      <Footer/>
    </BrowserRouter>
  </div>,
  document.getElementById('root')
);
